<template>
  <div>
    <leaflet-panel
        :body="body"
        :image="image"
        :show-buy-btn="showBuyBtn"
        :show-detail-btn="showDetailBtn"
        :title="title"
    />
    <!--    <ImageList :images="images"></ImageList>-->
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
               :title="`功能介绍`" :en_title="`Product Service`"></Paragraph>
    <div style="width: 80%; margin-left: 10%">
      <Logotitlebox :datalist="datalistx3"></Logotitlebox>
    </div>
    <div style="width: 80%; margin-left: 10%">
      <Logotitlebox :datalist="datalistx4"></Logotitlebox>
    </div>
    <Paragraph :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
               :title="`产品特点`" :en_title="`Features`"></Paragraph>
    <Titlestrlist :datalist="datalist" style="margin-bottom: 6rem"></Titlestrlist>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`产品优势`" :en_title="`Product Advantages`"></Paragraph>
    <div style="margin-left: 10%;width: 80%;font-size: 1.4rem;
            font-weight: 400;font-family: PingFang SC Medium, PingFang SC Medium-Medium;
            letter-spacing: 0.2rem;text-align: left">
      <span style="color: #2285ff"> ></span>  为企业和政府提供了多种方式来利用区块链技术的优势，而无需重新架构其当前系统。
    </div>
    <div style="width: 70%;margin-left: 15%;display: flex;flex-direction: row;margin-top: 2rem">
      <div style="width: 25%;height: 25rem;">
        <div style="width: 90%;height:90%;margin: 5%;box-shadow:0px 0px 5px  #6e6e6e">
          <div style="height: 40%;display:flex;flex-direction:column;
          justify-content:center;">
            <div style="height: 2.5rem;width:2.5rem;margin: 15%;padding: 0">
              <img src="@/assets/image/contentsvg/king.svg" style="width: 100%;height: 100%">
            </div>
          </div>
          <div style="height: 20%;display:flex;flex-direction:column;
          justify-content:center;text-align: left;font-size: 1rem;font-weight: 600
          ;margin-left: 10%">
            保护现有系统
          </div>
          <div style="height: 40%;
          text-align: left;font-size: 1rem;margin-left: 10%;width: 80%">
            使业务流程的后端自动化，以消除交易对手分享并减少对三方的依赖。
          </div>
        </div>
      </div>
      <div style="width: 25%;height: 25rem;">
        <div style="width: 90%;height:90%;margin: 5%;box-shadow:0px 0px 5px  #6e6e6e">
          <div style="height: 40%;display:flex;flex-direction:column;
          justify-content:center;">
            <div style="height: 3rem;width:3rem;margin: 15%;padding: 0">
              <img src="@/assets/image/contentsvg/box.svg" style="width: 100%;height: 100%">
            </div>
          </div>
          <div style="height: 20%;display:flex;flex-direction:column;
          justify-content:center;text-align: left;font-size: 1rem;font-weight: 600
          ;margin-left: 10%">
            增加用户的透明度
          </div>
          <div style="height: 40%;
          text-align: left;font-size: 1rem;margin-left: 10%;width: 80%">
            合并分散的数据输入，为您的用户提供有关关键服务执行的卓越保证。
          </div>
        </div>
      </div>
      <div style="width: 25%;height: 25rem;">
        <div style="width: 90%;height:90%;margin: 5%;box-shadow:0px 0px 5px  #6e6e6e">
          <div style="height: 40%;display:flex;flex-direction:column;
          justify-content:center;">
            <div style="height: 3rem;width:3rem;margin: 15%;padding: 0">
              <img src="@/assets/image/contentsvg/fly.svg" style="width: 100%;height: 100%">
            </div>
          </div>
          <div style="height: 20%;display:flex;flex-direction:column;
          justify-content:center;text-align: left;font-size: 1rem;font-weight: 600
          ;margin-left: 10%">
            连接到任何区块链
          </div>
          <div style="height: 40%;
          text-align: left;font-size: 1rem;margin-left: 10%;width: 80%">
            使用单个软件将现有系统链接到任何当前或将来的区块链环境。
          </div>
        </div>
      </div>
      <div style="width: 25%;height: 25rem;">
        <div style="width: 90%;height:90%;margin: 5%;box-shadow:0px 0px 5px  #6e6e6e">
          <div style="height: 40%;display:flex;flex-direction:column;
          justify-content:center;">
            <div style="height: 3rem;width:3rem;margin: 15%;padding: 0">
              <img src="@/assets/image/contentsvg/tikit.svg" style="width: 100%;height: 100%">
            </div>
          </div>
          <div style="height: 20%;display:flex;flex-direction:column;
          justify-content:center;text-align: left;font-size: 1rem;font-weight: 600
          ;margin-left: 10%">
            通过您当前的API获利
          </div>
          <div style="height: 40%;
          text-align: left;font-size: 1rem;margin-left: 10%;width: 80%">
            将您的数据和API服务直接出售给智能合约应用程序，以扩展您的潜在市场。
          </div>
        </div>
      </div>

    </div>

    <Paragraph :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
               :title="`应用场景`" :en_title="`Application Scenarios`"></Paragraph>
    <div style="width: 80%;margin-left: 10%;margin-top: 4rem">
      <Lettertitleblock :datalist="datalistx"
                        style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
      <Lettertitleblock :datalist="datalistx2"
                        style="display: flex;flex-direction: row;justify-content:center ;
                        width: 100%"></Lettertitleblock>
    </div>

  </div>
</template>

<script>
import LeafletPanel from '@/components/common/LeafletPanel';
// import ImageList from '@/components/common/ImageList';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';
import Lettertitleblock from '@/components/chain/basic/common/lettertitleblockleft';

export default {
  name: 'BaaSLeaflet',
  components: {
    LeafletPanel,
    // ImageList,
    Paragraph,
    Logotitlebox,
    Titlestrlist,
    Lettertitleblock,
  },
  data() {
    return {
      datalistx: [
        {
          title: '分布式金融',
          imgsrc: 'A',
        }, {
          title: '零售支付',
          imgsrc: 'B',
        }, {
          title: '游戏性与随机性',
          imgsrc: 'C',
        },
        {
          title: '保险',
          imgsrc: 'D',
        }, {
          title: '企业系统',
          imgsrc: 'E',
        }, {
          title: '授权和身份 ',
          imgsrc: 'F',
        },
      ],
      datalist: [
        { title: '>  无缝连接任何API', content: '节点可以安全地储存API秘钥并管理账户登录信息，这使智能合约能够从任何链下系统和API获取数据包括受密码保护的认证API。' },
        { title: '> 去中心化 ', content: '在节点和数据源层面都实现去中心化，避免出现节点或数据源的单点失效，为用户保障数据传输的及时性和完整性' },
        { title: '> 数据签名', content: '节点需要对发送至智能合约的数据进行加密签名，因此用户可以查看哪个节点发送了哪些数据, 并查看节点的历史服务记录以了解其服务水平。' },
        { title: '> 服务协议', content: '请求数据的智能合约与预言机运营商需要在链上签署具有约束力的服务协议，约定预言机服务条款以及奖惩制度，为用户保障链下数据的质量。' },
        { title: '> 声誉系统', content: '将经过签名的链上数据传输至声誉系统，让用户可以做出理性选择，基于完成任务数量、服务客户数量和平均响应时间等各种指标判断节点的优劣。' },
        { title: '> 认证服务', content: '为节点提供KYC、节点地理位置以及基础架构安全评估等认证，提升节点的安全性和可靠性。' },
        { title: '> 高级加密技术和硬件保护', content: '零知识证明等高级加密技术以及可信执行环境等硬件保护可以为预言机带来额外的安全保障，比如向智能合约证明数据来源（注：证明数据来自某一服务器），向预言机隐藏数据内容，以及执行链下计算等。' },
      ],
      datalistx2: [

      ],
      datalistx4: [
        {
          imageurl: require('@/assets/image/contentsvg/box.svg'),
          title: '验 证',
          list: [
            '使用数据签名、区块链交易签名',
            'TLS签名、可信执行环境（TEE）',
            '证明以及零知识证明等',
            '各种工具为预言机服务提供加密证明',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/setting.svg'),
          title: '计 算',
          list: [
            '对数据进行运算',
            '比如基于多个预言机提交的数据',
            '计算出中位数，或基于不同类型的数据',
            '（如：个人风险情况、市场费率和',
            '资金成本等）生成保险报价',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/sperker.svg'),
          title: '广播',
          list: [
            '通过在区块链上签名并广播交易',
            '将数据和相关证明发送至链上智能合约',
            '&nbsp;',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/note.svg'),
          title: '数据输出',
          list: [
            '在智能合约执行时向链下系统发送数据',
            '比如将支付指令发送至传统支付网络',
            '或与信息物理系统进行交互',
            '&nbsp;',
            '&nbsp;',
          ],
        },
      ],
      datalistx3: [
        {
          imageurl: require('@/assets/image/contentsvg/tie.svg'),
          title: '等待响应',
          list: [
            '监控区块链网络',
            '扫描网络中是否有来自于用户或',
            '智能合约的链下数据请求',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '获取数据',
          list: [
            '从一个或多个链下系统获取数据',
            '（比如由第三方web服务器运行的',
            '链下API）',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/db.svg'),
          title: '格式化',
          list: [
            '将来自API的数据转换成',
            '区块链可读的格式',
            '并将链上数据转换成外部API兼容的格式',
            '以此打破链上链下的交流屏障',
          ],
        },
      ],
      title: '预言机服务',
      body:
          '域乎去中心化预言机是一套高度安全和灵活的基础架构。为任何区块链上的智能合约提供可靠的、防篡改的输入和输出，将智能合约连接到现实世界。',
      image: require('@/assets/image/chain/va/oracle/oracle.webp'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/va/oracle/7_01.jpg'),
        require('@/assets/image/chain/va/oracle/7_02.jpg'),
        require('@/assets/image/chain/va/oracle/7_03.jpg'),
        require('@/assets/image/chain/va/oracle/7_04.jpg'),
      ],
    };
  },
};
</script>

<style scoped></style>
